.button {
  @include font-spec(14px, 14px);
  appearance: none;
  background-color: getColor(button, background);
  border: none;
  color: getColor(button, text);
  transition: background-color 0.15s ease, color 0.15s ease;
  cursor: pointer;
  padding: 1rem 2rem;
  border-radius: 4px;
  font-weight: 700;
  text-decoration: none;
  border: 2px solid getColor(button, border);

  @include font-spec(14px, 14px);

  &:hover, &:focus, &:active {
    background-color: getColor(button, background-hover);
    color: getColor(button, text-hover);
  }

  &--topnav {
    padding: .5rem 1rem;
    margin: 0 0 0 .5rem;
  }

  &--form {
    width: 100%;
    border:none;
    outline: none;
  }
}
