$breakpoint-minimum: 320px;
$breakpoint-header-full: 769px;

$breakpoint: (
  header: (
    full: "(min-width: #{$breakpoint-header-full})"
  ),
  headline: (
    medium: "(min-width: 640px)"
  ),
  footer: (
    full: "(min-width: 960px)"
  ),
  nav: (
    visible: "(min-width: 960px)"
  ),
  nav-button: (
    hidden: "(min-width: 960px)"
  ),
  subnav: (
    visible: "(min-width: 960px)"
  ),
  container: (
    full: "(min-width: 1024px)"
  ),
  kirbytext: (
    float-around-image: "(min-width: 768px)"
  ),
  menu-wrapper: (
    full: "(min-width: 1024px)"
  ),
  column: (
    full: "(min-width: 960px)"
  ),
  file: (
    full: "(min-width: 960px)"
  ),
  sidebar: (
    aside: "(min-width: 960px)"
  ),
  article-list: (
    image-text-aside: "(min-width: 640px)",
    columns: "(min-width: 640px)"
  ),
  title: (
    inline: "(min-width: 640px)"
  )
);

/*
  Usage:

  @include breakpoint(header, full) {
   ...
  }
*/
