.list {
  list-style-type: none;

  &__item {
    position: relative;

    &:hover {
      img {
        transform: scale(1.05);
      }
    }
  }
}