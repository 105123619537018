.header {
  max-width: 1280px;
  padding: 1rem;
  margin: 0 auto 3vw;
  display: flex;
  justify-content: space-between;
  flex-direction: column-reverse;

  @include breakpoint(header, full) {
    flex-direction: row;
  }

  &__topnav {
    justify-content: space-between;
    align-items: flex-start;
    flex: 1 1 auto;
    display: flex;
    margin: 0 0 .5rem;

    @include breakpoint(header, full) {
      margin: 0 0 1rem;
      margin: 0;
    }
  }
}