.spacer {
  &--size-1 {
    height: 1rem;
  }
  &--size-3 {
    height: 3rem;
  }
  &--size-6 {
    height: 6rem;
  }
}