@function calculaterem($size) {
  $remsize: $size / 16px;
  @return #{$remsize}rem;
}

@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}

@function getBreakpoint($key, $key2:null) {
  @if $key2 {
    @return map-get(map-get($breakpoint, $key), $key2);
  } @else {
    @return map-get($breakpoint, $key);
  }
}

@function getColor($key, $key2:null) {
  @if $key2 {
    @return map-get(map-get($color, $key), $key2);
  } @else {
    @return map-get($color, $key);
  }
}

@mixin font-spec($size: 18px, $height: 23px) {
  font-size: calculaterem($size);
  line-height: calculaterem($height);
}

@mixin breakpoint($key, $key2: false) {
  @media screen and #{getBreakpoint($key, $key2)} {
    @content;
  }
}

@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      font-size: $min-font-size;
      @media screen and (min-width: $min-vw) {
        font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
      }
      @media screen and (min-width: $max-vw) {
        font-size: $max-font-size;
      }
    }
  }
}

@mixin fluid-size($attribute, $min-vw, $max-vw, $min-size, $max-size) {
  #{$attribute}: $min-size;
  @media screen and (min-width: $min-vw) {
    #{$attribute}: calc(#{$min-size} + #{strip-unit($max-size - $min-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
  }
  @media screen and (min-width: $max-vw) {
    #{$attribute}: $max-size;
  }
}