.nav {
  max-width: 1280px;
  margin: 2rem auto;
  padding: 0 1rem;

  &__list {
    display: flex;
    justify-content: flex-end;
    list-style-type: none;
  }

  &__list-item {
    padding: 0 0.5rem;
  }

  &__link {
    @include font-spec(20px, 28px);
    display: block;
    color: getColor(nav, link);
    letter-spacing: 3px;
    text-transform: lowercase;
    text-decoration: none;
    position: relative;

    @media screen and (min-width: 640px) {
      @include font-spec(28px, 36px);
    }

    &:after {
      position: absolute;
      right: 0;
      bottom: -4px;
      left: 0;
      width: 100%;
      height: 4px;
      content: "";
      transition: transform .2s ease, background-color .2s ease;
      transform: scaleX(.9);
      background-color: getColor(nav, link);
    }

    &:hover:after {
      background-color: #c5230f;
      transform: scaleX(.7);
    }

    &--active {
      &:after {
        transform: scaleX(.9);
        background-color: #c5230f;
      }
    }


  }
}
