.topnav {
  &__container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__link {
    @include font-spec(15px, 20px);
    font-weight: 600;
    padding: 0 .5rem;
    border: 1px solid transparent;
    display: inline-block;
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
    color: getColor(topnav, link);
    text-decoration: none;

    &--active {
      text-decoration: underline;
    }

    &:last-child {
      padding: 0 0 0 .5rem;
    }
  }
}
