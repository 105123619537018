.copy {
  @include font-spec(16px, 26px);

  &--subline {
    @include font-spec(28px, 36px);
    font-weight: 500;
    font-family: "indie-flower-regular", sans-serif;
    max-width: 800px;
    margin: 0 auto;
  }

  &--light {
    color: getColor(copy, light);
  }

  &--centered {
    text-align: center;
  }

  &--small {
    @include font-spec(14px, 18px);
  }

  &--bold {
    font-weight: bold;
  }

  &--time {
    font-size: 1rem;
  }

  &--margin {
    margin-bottom: .5rem;
  }
}
