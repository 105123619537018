*,
*:after,
*:before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.body {
  font-family:
    -apple-system,        // Safari for OS X and iOS (San Francisco)
    BlinkMacSystemFont,   // Chrome < 56 for OS X (San Francisco)
    'Segoe UI',           // Windows
    Roboto,               // Android
    'Helvetica Neue',     // Basic web fallback
    Arial,
    sans-serif;
  background-color: $background-image-color;
  background-image: url('../images/earth-at-night.jpg');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: top center;
  background-size: cover;
}

// a {
//   color: getColor(base, link);
//   text-decoration: none;
//   &:hover {
//     text-decoration: none;
//   }
// }

img  {
  width: 100%;
  vertical-align: middle;
}

iframe {
  max-width: 100%;
  width: 100%;
}
