.grid {
  display: grid;
  list-style: none;
  grid-gap: 1rem;
  line-height: 0;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-flow: dense;

  @media screen and (min-width: 1024px) {
    grid-template-columns: repeat(4, 1fr);
  }

  img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 1s cubic-bezier(0.075, 0.82, 0.165, 1)

  }

  &__item {
    --cols: 1;
    --rows: 1;
    display: block;
    padding-bottom: 75%;
    position: relative;
    overflow: hidden;
    background: transparent;
    line-height: 0;
    cursor: pointer;

    &:first-child {
      --cols: 2;
      --rows: 2;
    }
    &:first-child {
      --cols: 2;
      --rows: 2;
    }
    &:nth-child(3) {
      --cols: 2;
    }
    &:nth-child(6) {
      --rows: 2;
    }
    &:nth-child(2n+7) {
      --cols: 2;
    }

    @media (min-width: 1024px) {
      grid-column-start: span var(--cols);
      grid-row-start: span var(--rows);
      padding-bottom: 56.25%;
    }

    &:hover {
      img {
        transform: scale(.95);
      }
    }
  }
}
