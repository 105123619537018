.container {
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;

  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  padding-left: 1rem;
  padding-right: 1rem;

  @include breakpoint(container, full) {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  &--small {
    max-width: 1024px;
  }

  &--slim {
    padding-top: 0;
    padding-bottom: 0;
  }

  &--no-padding {
    padding: 0;
  }
}
