// font icons support

@font-face {
  font-family: "lg";
  src: url("../fonts/lightgallery.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

.lg-icon {
  font-family: "lg";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
