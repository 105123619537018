.form {
  max-width: 320px;
  background-color: rgba(255,255,255,0.85);
  padding: 26px 30px 30px;
  margin: 0 auto;
  display: block;
  border-radius: 3px;

  &__input {
    @include font-spec(18px, 42px);
    display: block;
    border: none;
    margin: .25rem 0 2rem;
    width: 100%;
    border-radius: 4px;
    outline: none;
    background-color: getColor(form, input-background);
    border-bottom: 3px solid transparent;
    padding: 0 .5rem;
    &:focus{
      border-bottom: 3px solid getColor(form, input-border);
    }
  }

  &__label {
    @include font-spec(15px, 20px);
    font-weight: 700;
  }

  &__alert {
    @include font-spec(16px, 20px);
    font-weight: 700;
    margin: 1rem 0 1.5rem;
    padding: 0;
    color: #{$alert};
  }
}
