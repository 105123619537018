.album {
  &__header {
    display: flex;
    margin: 0 0 1rem 0;
    max-height: 50px;
  }

  &__navigation {
    margin: 0 .5rem 0 0;
    display: flex;
    padding: .5rem .25rem .5rem .25rem;

    @media (min-width: 640px) {
      padding: .5rem .5rem .5rem .5rem;
    }

    &:hover {
      .icon {
        transform: scale(1.3);
      }
    }
  }
}