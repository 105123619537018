/*
  Usage:

  @include breakpoint(header, full) {
   ...
  }
*/
@font-face {
  font-family: "indie-flower-regular";
  src: url("../fonts/indie-flower-regular.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "lg";
  src: url("../fonts/lightgallery.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

/*
  Usage:

  @include getColor(header, full) {
   ...
  }

  The key e.g. `header` should be named after the class/file it is used in.
*/
.spacer {
  height: 1rem; }
  .spacer--double {
    height: 2rem; }
  .spacer--triple {
    height: 3rem; }

@font-face {
  font-family: "lg";
  src: url("../fonts/lightgallery.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

.lg-icon {
  font-family: "lg";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.lg-actions .lg-next, .lg-actions .lg-prev {
  background-color: rgba(0, 0, 0, 0.45);
  border-radius: 2px;
  color: #999;
  cursor: pointer;
  display: block;
  font-size: 22px;
  margin-top: -10px;
  padding: 8px 10px 9px;
  position: absolute;
  top: 50%;
  z-index: 1080; }
  .lg-actions .lg-next.disabled, .lg-actions .lg-prev.disabled {
    pointer-events: none;
    opacity: 0.5; }
  .lg-actions .lg-next:hover, .lg-actions .lg-prev:hover {
    color: #fff; }

.lg-actions .lg-next {
  right: 20px; }
  .lg-actions .lg-next:before {
    content: "\e095"; }

.lg-actions .lg-prev {
  left: 20px; }
  .lg-actions .lg-prev:after {
    content: "\e094"; }

@-webkit-keyframes lg-right-end {
  0% {
    left: 0; }
  50% {
    left: -30px; }
  100% {
    left: 0; } }

@-moz-keyframes lg-right-end {
  0% {
    left: 0; }
  50% {
    left: -30px; }
  100% {
    left: 0; } }

@-ms-keyframes lg-right-end {
  0% {
    left: 0; }
  50% {
    left: -30px; }
  100% {
    left: 0; } }

@keyframes lg-right-end {
  0% {
    left: 0; }
  50% {
    left: -30px; }
  100% {
    left: 0; } }

@-webkit-keyframes lg-left-end {
  0% {
    left: 0; }
  50% {
    left: 30px; }
  100% {
    left: 0; } }

@-moz-keyframes lg-left-end {
  0% {
    left: 0; }
  50% {
    left: 30px; }
  100% {
    left: 0; } }

@-ms-keyframes lg-left-end {
  0% {
    left: 0; }
  50% {
    left: 30px; }
  100% {
    left: 0; } }

@keyframes lg-left-end {
  0% {
    left: 0; }
  50% {
    left: 30px; }
  100% {
    left: 0; } }

.lg-outer.lg-right-end .lg-object {
  -webkit-animation: lg-right-end 0.3s;
  -o-animation: lg-right-end 0.3s;
  animation: lg-right-end 0.3s;
  position: relative; }

.lg-outer.lg-left-end .lg-object {
  -webkit-animation: lg-left-end 0.3s;
  -o-animation: lg-left-end 0.3s;
  animation: lg-left-end 0.3s;
  position: relative; }

.lg-toolbar {
  z-index: 1082;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.45); }
  .lg-toolbar .lg-icon {
    color: #999;
    cursor: pointer;
    float: right;
    font-size: 24px;
    height: 47px;
    line-height: 27px;
    padding: 10px 0;
    text-align: center;
    width: 50px;
    text-decoration: none !important;
    outline: medium none;
    -webkit-transition: color 0.2s linear;
    -o-transition: color 0.2s linear;
    transition: color 0.2s linear; }
    .lg-toolbar .lg-icon:hover {
      color: #fff; }
  .lg-toolbar .lg-close:after {
    content: "\e070"; }
  .lg-toolbar .lg-download:after {
    content: "\e0f2"; }

.lg-sub-html {
  background-color: rgba(0, 0, 0, 0.45);
  bottom: 0;
  color: #eee;
  font-size: 16px;
  left: 0;
  padding: 10px 40px;
  position: fixed;
  right: 0;
  text-align: center;
  z-index: 1080; }
  .lg-sub-html h4 {
    margin: 0;
    font-size: 13px;
    font-weight: bold; }
  .lg-sub-html p {
    font-size: 12px;
    margin: 5px 0 0; }

#lg-counter {
  color: #999;
  display: inline-block;
  font-size: 16px;
  padding-left: 20px;
  padding-top: 12px;
  vertical-align: middle; }

.lg-toolbar, .lg-prev, .lg-next {
  opacity: 1;
  -webkit-transition: -webkit-transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear;
  -moz-transition: -moz-transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear;
  -o-transition: -o-transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear;
  transition: transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear; }

.lg-hide-items .lg-prev {
  opacity: 0;
  -webkit-transform: translate3d(-10px, 0, 0);
  transform: translate3d(-10px, 0, 0); }

.lg-hide-items .lg-next {
  opacity: 0;
  -webkit-transform: translate3d(10px, 0, 0);
  transform: translate3d(10px, 0, 0); }

.lg-hide-items .lg-toolbar {
  opacity: 0;
  -webkit-transform: translate3d(0, -10px, 0);
  transform: translate3d(0, -10px, 0); }

body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-object {
  -webkit-transform: scale3d(0.5, 0.5, 0.5);
  transform: scale3d(0.5, 0.5, 0.5);
  opacity: 0;
  -webkit-transition: -webkit-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  -moz-transition: -moz-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  -o-transition: -o-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  transition: transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%; }

body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-item.lg-complete .lg-object {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  opacity: 1; }

.lg-progress-bar {
  background-color: #333;
  height: 5px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1083;
  opacity: 0;
  -webkit-transition: opacity 0.08s ease 0s;
  -moz-transition: opacity 0.08s ease 0s;
  -o-transition: opacity 0.08s ease 0s;
  transition: opacity 0.08s ease 0s; }
  .lg-progress-bar .lg-progress {
    background-color: #a90707;
    height: 5px;
    width: 0; }
  .lg-progress-bar.lg-start .lg-progress {
    width: 100%; }
  .lg-show-autoplay .lg-progress-bar {
    opacity: 1; }

.lg-autoplay-button:after {
  content: "\e01d"; }
  .lg-show-autoplay .lg-autoplay-button:after {
    content: "\e01a"; }

.lg-outer.lg-css3.lg-zoom-dragging .lg-item.lg-complete.lg-zoomable .lg-img-wrap, .lg-outer.lg-css3.lg-zoom-dragging .lg-item.lg-complete.lg-zoomable .lg-image {
  -webkit-transition-duration: 0s;
  transition-duration: 0s; }

.lg-outer.lg-use-transition-for-zoom .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  -moz-transition: -moz-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  -o-transition: -o-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s; }

.lg-outer.lg-use-left-for-zoom .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  -webkit-transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  -moz-transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  -o-transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s; }

.lg-outer .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden; }

.lg-outer .lg-item.lg-complete.lg-zoomable .lg-image {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
  -moz-transition: -moz-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
  -o-transition: -o-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
  transition: transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden; }

#lg-zoom-in:after {
  content: "\e311"; }

#lg-actual-size {
  font-size: 20px; }
  #lg-actual-size:after {
    content: "\e033"; }

#lg-zoom-out {
  opacity: 0.5;
  pointer-events: none; }
  #lg-zoom-out:after {
    content: "\e312"; }
  .lg-zoomed #lg-zoom-out {
    opacity: 1;
    pointer-events: auto; }

.group {
  *zoom: 1; }

.group:before,
.group:after {
  display: table;
  content: "";
  line-height: 0; }

.group:after {
  clear: both; }

.lg-outer {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  opacity: 0;
  -webkit-transition: opacity 0.15s ease 0s;
  -o-transition: opacity 0.15s ease 0s;
  transition: opacity 0.15s ease 0s; }
  .lg-outer * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .lg-outer.lg-visible {
    opacity: 1; }
  .lg-outer.lg-css3 .lg-item.lg-prev-slide, .lg-outer.lg-css3 .lg-item.lg-next-slide, .lg-outer.lg-css3 .lg-item.lg-current {
    -webkit-transition-duration: inherit !important;
    transition-duration: inherit !important;
    -webkit-transition-timing-function: inherit !important;
    transition-timing-function: inherit !important; }
  .lg-outer.lg-css3.lg-dragging .lg-item.lg-prev-slide, .lg-outer.lg-css3.lg-dragging .lg-item.lg-next-slide, .lg-outer.lg-css3.lg-dragging .lg-item.lg-current {
    -webkit-transition-duration: 0s !important;
    transition-duration: 0s !important;
    opacity: 1; }
  .lg-outer.lg-grab img.lg-object {
    cursor: -webkit-grab;
    cursor: -moz-grab;
    cursor: -o-grab;
    cursor: -ms-grab;
    cursor: grab; }
  .lg-outer.lg-grabbing img.lg-object {
    cursor: move;
    cursor: -webkit-grabbing;
    cursor: -moz-grabbing;
    cursor: -o-grabbing;
    cursor: -ms-grabbing;
    cursor: grabbing; }
  .lg-outer .lg {
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    max-height: 100%; }
  .lg-outer .lg-inner {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    white-space: nowrap; }
  .lg-outer .lg-item {
    background: url("../images/lightgallery/loading.gif") no-repeat scroll center center transparent;
    display: none !important; }
  .lg-outer.lg-css3 .lg-prev-slide,
  .lg-outer.lg-css3 .lg-current,
  .lg-outer.lg-css3 .lg-next-slide {
    display: inline-block !important; }
  .lg-outer.lg-css .lg-current {
    display: inline-block !important; }
  .lg-outer .lg-item,
  .lg-outer .lg-img-wrap {
    display: inline-block;
    text-align: center;
    position: absolute;
    width: 100%;
    height: 100%; }
    .lg-outer .lg-item:before,
    .lg-outer .lg-img-wrap:before {
      content: "";
      display: inline-block;
      height: 50%;
      width: 1px;
      margin-right: -1px; }
  .lg-outer .lg-img-wrap {
    position: absolute;
    padding: 0 5px;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0; }
  .lg-outer .lg-item.lg-complete {
    background-image: none; }
  .lg-outer .lg-item.lg-current {
    z-index: 1060; }
  .lg-outer .lg-image {
    display: inline-block;
    vertical-align: middle;
    max-width: 100%;
    max-height: 100%;
    width: auto !important;
    height: auto !important; }
  .lg-outer.lg-show-after-load .lg-item .lg-object,
  .lg-outer.lg-show-after-load .lg-item .lg-video-play {
    opacity: 0;
    -webkit-transition: opacity 0.15s ease 0s;
    -o-transition: opacity 0.15s ease 0s;
    transition: opacity 0.15s ease 0s; }
  .lg-outer.lg-show-after-load .lg-item.lg-complete .lg-object,
  .lg-outer.lg-show-after-load .lg-item.lg-complete .lg-video-play {
    opacity: 1; }
  .lg-outer .lg-empty-html {
    display: none; }
  .lg-outer.lg-hide-download #lg-download {
    display: none; }

.lg-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1040;
  background-color: #000;
  opacity: 0;
  -webkit-transition: opacity 0.15s ease 0s;
  -o-transition: opacity 0.15s ease 0s;
  transition: opacity 0.15s ease 0s; }
  .lg-backdrop.in {
    opacity: 1; }

.lg-css3.lg-no-trans .lg-prev-slide,
.lg-css3.lg-no-trans .lg-next-slide,
.lg-css3.lg-no-trans .lg-current {
  -webkit-transition: none 0s ease 0s !important;
  -moz-transition: none 0s ease 0s !important;
  -o-transition: none 0s ease 0s !important;
  transition: none 0s ease 0s !important; }

.lg-css3.lg-use-css3 .lg-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden; }

.lg-css3.lg-use-left .lg-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden; }

.lg-css3.lg-fade .lg-item {
  opacity: 0; }
  .lg-css3.lg-fade .lg-item.lg-current {
    opacity: 1; }
  .lg-css3.lg-fade .lg-item.lg-prev-slide, .lg-css3.lg-fade .lg-item.lg-next-slide, .lg-css3.lg-fade .lg-item.lg-current {
    -webkit-transition: opacity 0.1s ease 0s;
    -moz-transition: opacity 0.1s ease 0s;
    -o-transition: opacity 0.1s ease 0s;
    transition: opacity 0.1s ease 0s; }

.lg-css3.lg-slide.lg-use-css3 .lg-item {
  opacity: 0; }
  .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1; }
  .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide, .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide, .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

.lg-css3.lg-slide.lg-use-left .lg-item {
  opacity: 0;
  position: absolute;
  left: 0; }
  .lg-css3.lg-slide.lg-use-left .lg-item.lg-prev-slide {
    left: -100%; }
  .lg-css3.lg-slide.lg-use-left .lg-item.lg-next-slide {
    left: 100%; }
  .lg-css3.lg-slide.lg-use-left .lg-item.lg-current {
    left: 0;
    opacity: 1; }
  .lg-css3.lg-slide.lg-use-left .lg-item.lg-prev-slide, .lg-css3.lg-slide.lg-use-left .lg-item.lg-next-slide, .lg-css3.lg-slide.lg-use-left .lg-item.lg-current {
    -webkit-transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -moz-transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -o-transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

.grid {
  display: grid;
  list-style: none;
  grid-gap: 1rem;
  line-height: 0;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-flow: dense; }
  @media screen and (min-width: 1024px) {
    .grid {
      grid-template-columns: repeat(4, 1fr); } }
  .grid img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 1s cubic-bezier(0.075, 0.82, 0.165, 1); }
  .grid__item {
    --cols: 1;
    --rows: 1;
    display: block;
    padding-bottom: 75%;
    position: relative;
    overflow: hidden;
    background: transparent;
    line-height: 0;
    cursor: pointer; }
    .grid__item:first-child {
      --cols: 2;
      --rows: 2; }
    .grid__item:first-child {
      --cols: 2;
      --rows: 2; }
    .grid__item:nth-child(3) {
      --cols: 2; }
    .grid__item:nth-child(6) {
      --rows: 2; }
    .grid__item:nth-child(2n+7) {
      --cols: 2; }
    @media (min-width: 1024px) {
      .grid__item {
        grid-column-start: span var(--cols);
        grid-row-start: span var(--rows);
        padding-bottom: 56.25%; } }
    .grid__item:hover img {
      transform: scale(0.95); }

.album__header {
  display: flex;
  margin: 0 0 1rem 0;
  max-height: 50px; }

.album__navigation {
  margin: 0 .5rem 0 0;
  display: flex;
  padding: .5rem .25rem .5rem .25rem; }
  @media (min-width: 640px) {
    .album__navigation {
      padding: .5rem .5rem .5rem .5rem; } }
  .album__navigation:hover .icon {
    transform: scale(1.3); }

.albums {
  display: grid;
  list-style: none;
  grid-gap: 1rem;
  line-height: 0;
  margin-bottom: 2rem; }

@media screen and (min-width: 30em) {
  .albums {
    grid-template-columns: repeat(2, 1fr); } }

@media screen and (min-width: 60em) {
  .albums {
    grid-template-columns: repeat(3, 1fr); }
  .albums[data-even] {
    grid-template-columns: repeat(4, 1fr); } }

.albums li {
  overflow: hidden;
  background: #000; }

.albums img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all .3s; }

.albums img:hover {
  opacity: .2; }

.icon {
  transition: transform .1s ease; }
  .icon--large {
    max-width: 32px;
    width: 100%; }

.figure__caption {
  font-size: 0.875rem;
  line-height: 1.125rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  color: #ffffff;
  letter-spacing: .125em;
  font-weight: 400;
  z-index: 1;
  left: 0;
  bottom: 0;
  background: rgba(1, 0, 31, 0.75);
  padding: .2rem .5rem .2rem;
  width: 100%;
  text-align: center; }
  @media screen and (min-width: 640px) {
    .figure__caption {
      font-size: 1.125rem;
      line-height: 1.5rem; } }

.spacer--size-1 {
  height: 1rem; }

.spacer--size-3 {
  height: 3rem; }

.spacer--size-6 {
  height: 6rem; }

*,
*:after,
*:before {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

.body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  background-color: #01001f;
  background-image: url("../images/earth-at-night.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: top center;
  background-size: cover; }

img {
  width: 100%;
  vertical-align: middle; }

iframe {
  max-width: 100%;
  width: 100%; }

.header {
  max-width: 1280px;
  padding: 1rem;
  margin: 0 auto 3vw;
  display: flex;
  justify-content: space-between;
  flex-direction: column-reverse; }
  @media screen and (min-width: 769px) {
    .header {
      flex-direction: row; } }
  .header__topnav {
    justify-content: space-between;
    align-items: flex-start;
    flex: 1 1 auto;
    display: flex;
    margin: 0 0 .5rem; }
    @media screen and (min-width: 769px) {
      .header__topnav {
        margin: 0 0 1rem;
        margin: 0; } }

.container {
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  padding-left: 1rem;
  padding-right: 1rem; }
  @media screen and (min-width: 1024px) {
    .container {
      padding-top: 2rem;
      padding-bottom: 2rem; } }
  .container--small {
    max-width: 1024px; }
  .container--slim {
    padding-top: 0;
    padding-bottom: 0; }
  .container--no-padding {
    padding: 0; }

.nav {
  max-width: 1280px;
  margin: 2rem auto;
  padding: 0 1rem; }
  .nav__list {
    display: flex;
    justify-content: flex-end;
    list-style-type: none; }
  .nav__list-item {
    padding: 0 0.5rem; }
  .nav__link {
    font-size: 1.25rem;
    line-height: 1.75rem;
    display: block;
    color: #c2bcbc;
    letter-spacing: 3px;
    text-transform: lowercase;
    text-decoration: none;
    position: relative; }
    @media screen and (min-width: 640px) {
      .nav__link {
        font-size: 1.75rem;
        line-height: 2.25rem; } }
    .nav__link:after {
      position: absolute;
      right: 0;
      bottom: -4px;
      left: 0;
      width: 100%;
      height: 4px;
      content: "";
      transition: transform .2s ease, background-color .2s ease;
      transform: scaleX(0.9);
      background-color: #c2bcbc; }
    .nav__link:hover:after {
      background-color: #c5230f;
      transform: scaleX(0.7); }
    .nav__link--active:after {
      transform: scaleX(0.9);
      background-color: #c5230f; }

.main {
  padding-bottom: 8rem; }
  .main--album {
    background-color: aliceblue; }

.page {
  min-height: 100vh; }

.topnav__container {
  display: flex;
  flex-direction: row;
  justify-content: space-between; }

.topnav__link {
  font-size: 0.938rem;
  line-height: 1.25rem;
  font-weight: 600;
  padding: 0 .5rem;
  border: 1px solid transparent;
  display: inline-block;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  color: #c2bcbc;
  text-decoration: none; }
  .topnav__link--active {
    text-decoration: underline; }
  .topnav__link:last-child {
    padding: 0 0 0 .5rem; }

.language {
  display: flex;
  justify-content: flex-end; }

.logo {
  display: flex;
  align-items: center;
  text-decoration: none;
  flex: 1 0 auto; }
  .logo__image {
    max-width: 220px;
    width: 20%; }
  .logo__text {
    font-weight: 400;
    color: #c2bcbc;
    display: inline-block;
    margin: .4rem 0 0 1rem;
    font-family: "indie-flower-regular", sans-serif; }
    .logo__text {
      font-size: 32px; }
      @media screen and (min-width: 320px) {
        .logo__text {
          font-size: calc(32px + 58 * ((100vw - 320px) / 1600)); } }
      @media screen and (min-width: 1920px) {
        .logo__text {
          font-size: 90px; } }

.center-children {
  display: flex;
  justify-content: center; }

.footer {
  color: #c2bcbc;
  margin: 5rem  0 0;
  width: 100%; }
  .footer__text {
    text-align: right; }
  .footer__container {
    display: flex;
    justify-content: space-between; }
  .footer__link {
    font-size: 0.938rem;
    line-height: 1.25rem;
    font-weight: 600;
    padding: 0 .5rem;
    border: 1px solid transparent;
    display: inline-block;
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
    color: #c2bcbc;
    text-decoration: none; }
    .footer__link--active {
      text-decoration: underline; }

.copy {
  font-size: 1rem;
  line-height: 1.625rem; }
  .copy--subline {
    font-size: 1.75rem;
    line-height: 2.25rem;
    font-weight: 500;
    font-family: "indie-flower-regular", sans-serif;
    max-width: 800px;
    margin: 0 auto; }
  .copy--light {
    color: #c2bcbc; }
  .copy--centered {
    text-align: center; }
  .copy--small {
    font-size: 0.875rem;
    line-height: 1.125rem; }
  .copy--bold {
    font-weight: bold; }
  .copy--time {
    font-size: 1rem; }
  .copy--margin {
    margin-bottom: .5rem; }

.list {
  list-style-type: none; }
  .list__item {
    position: relative; }
    .list__item:hover img {
      transform: scale(1.05); }

.form {
  max-width: 320px;
  background-color: rgba(255, 255, 255, 0.85);
  padding: 26px 30px 30px;
  margin: 0 auto;
  display: block;
  border-radius: 3px; }
  .form__input {
    font-size: 1.125rem;
    line-height: 2.625rem;
    display: block;
    border: none;
    margin: .25rem 0 2rem;
    width: 100%;
    border-radius: 4px;
    outline: none;
    background-color: #fff;
    border-bottom: 3px solid transparent;
    padding: 0 .5rem; }
    .form__input:focus {
      border-bottom: 3px solid #01001f; }
  .form__label {
    font-size: 0.938rem;
    line-height: 1.25rem;
    font-weight: 700; }
  .form__alert {
    font-size: 1rem;
    line-height: 1.25rem;
    font-weight: 700;
    margin: 1rem 0 1.5rem;
    padding: 0;
    color: #d07e00; }

.headline {
  color: #c2bcbc; }
  .headline--primary, h1 {
    font-size: 2.25rem;
    line-height: 2.875rem;
    font-weight: 700;
    margin: 1rem 0;
    position: relative; }
    @media screen and (min-width: 640px) {
      .headline--primary, h1 {
        font-size: 2.625rem;
        line-height: 3.25rem; } }
  .headline--home {
    font-size: 2.25rem;
    line-height: 2.875rem;
    text-align: center;
    font-family: "indie-flower-regular", sans-serif; }
    @media screen and (min-width: 640px) {
      .headline--home {
        font-size: 3.125rem;
        line-height: 4.5rem; } }
  .headline--album {
    font-size: 1.125rem;
    line-height: 2rem;
    padding: .5rem .5rem .5rem .5rem;
    display: inline-block;
    letter-spacing: .15rem;
    font-weight: 500;
    align-items: center;
    margin: 0; }
    @media screen and (min-width: 769px) {
      .headline--album {
        font-size: 1.75rem;
        line-height: 2.25rem; } }
  .headline--secondary, h2 {
    font-size: 1.5rem;
    line-height: 2.125rem;
    font-weight: 400;
    margin-bottom: 1rem; }
  .headline--tertiary, h3 {
    font-size: 1.25rem;
    line-height: 1.875rem;
    font-weight: 400;
    margin-bottom: 1rem; }

.button {
  font-size: 0.875rem;
  line-height: 0.875rem;
  appearance: none;
  background-color: #01001f;
  border: none;
  color: #c2bcbc;
  transition: background-color 0.15s ease, color 0.15s ease;
  cursor: pointer;
  padding: 1rem 2rem;
  border-radius: 4px;
  font-weight: 700;
  text-decoration: none;
  border: 2px solid;
  font-size: 0.875rem;
  line-height: 0.875rem; }
  .button:hover, .button:focus, .button:active {
    background-color: #000006;
    color: #ffffff; }
  .button--topnav {
    padding: .5rem 1rem;
    margin: 0 0 0 .5rem; }
  .button--form {
    width: 100%;
    border: none;
    outline: none; }
