.figure {
  &__caption {
    @include font-spec(14px, 18px);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    color: #{$white};
    letter-spacing: .125em;
    font-weight: 400;
    z-index: 1;
    left: 0;
    bottom: 0;
    background: rgba(1, 0, 31, .75);
    padding: .2rem .5rem .2rem;
    width: 100%;
    text-align: center;

    @media screen and (min-width: 640px) {
      @include font-spec(18px, 24px);
    }
  }
}