.logo {
  display: flex;
  align-items: center;
  text-decoration: none;
  flex: 1 0 auto;

  &__image {
    max-width: 220px;
    width: 20%;
  }

  &__text {
    @include fluid-type(320px, 1920px, 32px, 90px);
    font-weight: 400;
    color: $text-color;
    display: inline-block;
    margin: .4rem 0 0 1rem;
    font-family: "indie-flower-regular", sans-serif;
  }
}