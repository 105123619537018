.spacer {
  height: 1rem;

  &--double {
    height: 2rem;
  }

  &--triple {
    height: 3rem;
  }  
}