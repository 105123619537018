.footer {
  color: $text-color;
  margin: 5rem  0 0;
  width: 100%;

  &__text {
    text-align: right;
  }

  &__container {
    display: flex;
    justify-content: space-between;
  }

  &__link {
    @include font-spec(15px, 20px);
    font-weight: 600;
    padding: 0 .5rem;
    border: 1px solid transparent;
    display: inline-block;
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
    color: getColor(topnav, link);
    text-decoration: none;

    &--active {
      text-decoration: underline;
    }

  }
}