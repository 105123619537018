@font-face {
  font-family: "indie-flower-regular";
  src: url("../fonts/indie-flower-regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "lg";
  src: url("../fonts/lightgallery.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
