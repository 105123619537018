$white: #ffffff;
$text-color: #c2bcbc;
$background-image-color:#01001f;

$primary: #01001f;
$secondary: #647ed5;
$alert: #d07e00;

$color: (
  base: (
    link: #4d4d4d,
    copy: #2d2d2d,
    bg-body: $white
  ),
  title: (
    color: $text-color
  ),
  nav: (
    link: $text-color,
    border: $primary
  ),
  copy: (
    light: $text-color
  ),
  headline: (
    primary: $text-color
  ),
  topnav: (
    link: $text-color,
    button-color: $primary,
    button-background-color: $primary,
    button-background-hover: $primary,
  ),
  button: (
    text: $text-color,
    text-hover: $white,
    background: $primary,
    background-hover: darken($primary, 5%),
  ),
  form: (
    input-background: #fff,
    input-border: $primary,
    label: $primary,
  ),

);
/*
  Usage:

  @include getColor(header, full) {
   ...
  }

  The key e.g. `header` should be named after the class/file it is used in.
*/
