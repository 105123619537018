.headline {
  color: $text-color;
  &--primary {
    @include font-spec(36px, 46px);

    font-weight: 700;
    margin: 1rem 0;
    position: relative;

    @include breakpoint(headline, medium) {
      @include font-spec(42px, 52px);
    }
  }

  &--home {
    @include font-spec(36px, 46px);
    text-align: center;
    font-family: "indie-flower-regular", sans-serif;
    @include breakpoint(headline, medium) {
      @include font-spec(50px, 72px);
    }
  }

  &--album {
    @include font-spec(18px, 32px);
    padding: .5rem .5rem .5rem .5rem;
    display: inline-block;
    letter-spacing: .15rem;
    font-weight: 500;
    align-items: center;

    margin: 0;
    @include breakpoint(header, full) {
      @include font-spec(28px, 36px);
    }
  }

  &--secondary {
    @include font-spec(24px, 34px);
    font-weight: 400;
    margin-bottom: 1rem;
  }

  &--tertiary {
    @include font-spec(20px, 30px);
    font-weight: 400;
    margin-bottom: 1rem;
  }

}

h1 {
  @extend .headline--primary;
}

h2 {
  @extend .headline--secondary;
}

h3 {
  @extend .headline--tertiary;
}
